<template>
  <b-modal
    id="facility-code"
    centered
    title="Facility Edit"
    :no-close-on-backdrop="true"
    @hidden="resetModal"
    @ok="sumbit"
  >
    <b-form>
      <!--  Name -->
      <b-form-group>
        <label for="email">Name:</label>
        <b-form-input
          id="name"
          v-model="code.name"
          type="text"
          placeholder="Name"
          disabled
        />
      </b-form-group>
      <!--  City -->
      <b-form-group>
        <label for="city">City:</label>
        <b-form-input
          id="city"
          v-model="code.city"
          type="text"
          placeholder="City"
          autocomplete="off"
        />
      </b-form-group>
      <!--  Address -->
      <b-form-group>
        <label for="address">Address:</label>
        <b-form-input
          id="address"
          v-model="code.address"
          type="text"
          placeholder="Address"
          autocomplete="off"
        />
      </b-form-group>
      <!--  State -->
      <b-form-group>
        <label for="state">State:</label>
        <v-select
          id="mc-role"
          v-model="code.state"
          label="state"
          :options="states"
          :reduce="state => state.state"
          placeholder="State"
          class="w-100"
          @input="changeState"
        >
          <template
            #option="{ state, abbreviated_state }"
            :index="abbreviated_state"
          >
            {{ state }}
          </template>
        </v-select>
      </b-form-group>
      <!--  State -->
      <b-form-group>
        <label for="abbreviated_state">Abbreviated state:</label>
        <v-select
          id="mc-role"
          v-model="code.abbreviated_state"
          label="abbreviated_state"
          :options="states"
          :reduce="state => state.abbreviated_state"
          placeholder="Abbreviated state"
          class="w-100"
          @input="changeAbbreviatedState"
        >
          <template
            #option="{ abbreviated_state }"
            :index="abbreviated_state"
          >
            {{ abbreviated_state }}
          </template>
        </v-select>
      </b-form-group>
      <!--  Zip Code -->
      <b-form-group>
        <label for="zipcode">Zip Code:</label>
        <b-form-input
          id="zipcode"
          v-model="code.zipcode"
          type="text"
          placeholder="Zip Code"
          autocomplete="off"
        />
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import {
  BModal, VBModal, BForm, BFormInput, BFormGroup,
} from 'bootstrap-vue'

import vSelect from 'vue-select'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import * as facilityService from '@/services/facility'
import states from '@/utils/state.json'

export default {
  components: {
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,
  },

  directives: {
    'b-modal': VBModal,
  },

  props: {
    code: {
      type: Object,
      default() {
        return {}
      },
    },
    getFacilityCodes: {
      type: Function,
      default() {
        return []
      },
    },
  },

  data() {
    return {
      states,
    }
  },

  methods: {
    resetModal() {

    },

    changeState(e) {
      const selectedItem = this.states.find(state => state.state === e)
      this.code.abbreviated_state = selectedItem.abbreviated_state
    },

    changeAbbreviatedState(e) {
      const selectedItem = this.states.find(state => state.abbreviated_state === e)
      this.code.state = selectedItem.state
    },

    async sumbit() {
      await facilityService.updateCode(this.code)
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.$nextTick(() => {
            this.$bvModal.hide('facility-code')
            this.$emit('getFacilityCodes')
          })
        })
        .catch(() => {
          this.$nextTick(() => {
            this.$bvModal.hide('facility-code')
          })
        })
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
