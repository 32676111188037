<template>
  <div>
    <div class="d-flex justify-content-end  flex-wrap mb-2 px-2">

      <!-- filter -->
      <b-form-group
        label=""
        label-cols-sm="2"
        label-align-sm="left"
        label-size="sm"
        label-for="filterInput"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Type to Search"
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
            >
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </div>

    <b-table
      striped
      hover
      responsive
      class="position-relative facilitycode"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
      <template #cell(action)="row">
        <span class="float-right">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item @click="editAction(row.item.id)">
              <feather-icon
                icon="Edit2Icon"
                class="mr-50"
              />
              <span>Edit</span>
            </b-dropdown-item>
            <b-dropdown-item @click="deleteAction(row.item.id)">
              <feather-icon
                icon="TrashIcon"
                class="mr-50"
              />
              <span>Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </span>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        label=""
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </div>
</template>

<script>
import {
  BTable, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BDropdown, BDropdownItem, BCardBody,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BDropdown,
    BDropdownItem,
  },
  props: {
    items: {
      type: Array,
      default() {
        return ''
      },
    },
  },
  data() {
    return {
      perPage: 30,
      pageOptions: [20, 30, 40],
      totalRows: 1,
      currentPage: 1,
      filter: null,
      filterOn: [],
      fields: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'city', label: 'City', sortable: true },
        { key: 'address', label: 'Address', sortable: true },
        { key: 'state', label: 'State', sortable: true },
        { key: 'zipcode', label: 'Zip Code', sortable: true },
        { key: 'action', label: 'Action', sortable: true },
      ],
    }
  },
  
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },

  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    editAction(id) {
      this.$emit('editAction', id)
    },

    deleteAction(id) {
      this.$emit('deleteAction', id)
    },
  },
}
</script>

<style>
    .facilitycode th[aria-colindex='6'] {
        text-align: right;
        padding-right: 25px;
    }
</style>
